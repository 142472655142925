import React, { useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../pages/Firebase-config';

const ScrollingNotification = () => {
  const [notifications, setNotifications] = useState([]);

  const fetchNotification = async () => {
    try {
      const docRef = doc(db, 'Notifications', 'kY8eT51rYgRVEZv37yht');
      const getData = await getDoc(docRef);
      if (getData.exists()) {
        const notificationsData = getData.data().Notification;
        setNotifications(notificationsData);
      } else {
        console.log("No such document found");
      }
    } catch (e) {
      console.log("Error fetching data from Notifications", e);
    }
  };

  useEffect(() => {
    fetchNotification();
  }, []);

  return (
    <div className='w-full bg-red-600'>
      <marquee>
        <div className='flex flex-row p-2'>
          {
            notifications.map((notification,index)=>{
              return(
                <p key={index} className='mx-4 text-yellow-200'>{notification} ||</p>
              );
            })
          }
        </div>
      </marquee>
    </div>
  );
};

export default ScrollingNotification;
